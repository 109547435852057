// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import SnackbarProvider from "./components/snackbar";
import { ThemeSettings } from "./components/settings";
import { MotionLazyContainer } from "./components/animate";
import AutoLogout from "./components/CustomFunction/AutoLogout";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router";

ReactGA.initialize("G-1MSDVC0ZNM");

// ----------------------------------------------------------------------

export default function App() {
  const pathname = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "page-view", page: pathname.pathname });
  }, [pathname]);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <AutoLogout>
          <ThemeSettings>
            <ThemeLocalization>
              <SnackbarProvider>
                <Router />
              </SnackbarProvider>
            </ThemeLocalization>
          </ThemeSettings>
        </AutoLogout>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
