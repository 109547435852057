import { CircularProgress, Stack, useTheme } from '@mui/material';
import { varFade } from '../animate';
import { m } from 'framer-motion';

function ApiDataLoading() {
  const theme = useTheme();
  return (
    <Stack flexDirection={'row'} justifyContent={'center'} my={2}>
      <m.div variants={varFade().in}>
        <CircularProgress sx={{ color: theme.palette.primary.main }} />
      </m.div>
    </Stack>
  );
}

export default ApiDataLoading;
