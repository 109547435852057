// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: icon("ic_user"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  mail: icon("ic_dashboard"),
  leads: icon("ic_leads"),
  network: icon("ic_network"),
  scheme: icon("ic_scheme"),
  product: icon("ic_product"),
  vendor: icon("ic_vendor"),
  fund: icon("ic_fund"),
  auto: icon("ic_auto"),
  content: icon("ic_content"),
  tools: icon("ic_tools"),
  reports: icon("ic_reports"),
  documents: icon("ic_documents"),
  finger: icon("ic_finger"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.dashboard,
        icon: ICONS.dashboard,
      },
      {
        title: "New Leads",
        path: PATH_DASHBOARD.newleads,
        icon: ICONS.leads,
      },
      {
        title: "Our Network",
        path: PATH_DASHBOARD.ournetwork.root,
        icon: ICONS.fund,
        children: [
          {
            title: "Distribution Network",
            path: PATH_DASHBOARD.ournetwork.distributionnetwork,
          },
          {
            title: "User Map",
            path: PATH_DASHBOARD.ournetwork.usermap,
          },
        ],
      },

      {
        title: "Scheme Management",
        path: PATH_DASHBOARD.scheme.root,
        icon: ICONS.scheme,
        children: [
          { title: "Manage Scheme", path: PATH_DASHBOARD.scheme.allscheme },
          { title: "Map Scheme", path: PATH_DASHBOARD.scheme.MapSchemeSetting },
          {
            title: "Manage BBPS Scheme",
            path: PATH_DASHBOARD.scheme.AllbbpsScheme,
          },
          {
            title: "Map BBPS Scheme",
            path: PATH_DASHBOARD.scheme.mapbbpsScheme,
          },
          {
            title: "Manage BBPS2 Scheme",
            path: PATH_DASHBOARD.scheme.Allbbps2Scheme,
          },
          {
            title: "Map BBPS2 Scheme",
            path: PATH_DASHBOARD.scheme.mapbbps2Scheme,
          },
          // {
          //   title: "Manage Loan Scheme",
          //   path: PATH_DASHBOARD.scheme.AllLaonScheme,
          // },
          // {
          //   title: "Map Loan Scheme",
          //   path: PATH_DASHBOARD.scheme.maploanscheme,
          // },
        ],
      },
      {
        title: "Product Management",
        path: PATH_DASHBOARD.product.root,
        icon: ICONS.product,
        children: [
          {
            title: "Add New Product",
            path: PATH_DASHBOARD.product.productmanagement,
          },
          {
            title: "Product Control",
            path: PATH_DASHBOARD.product.productControl,
          },
          // {
          //   title: "Assign Vendor",
          //   path: PATH_DASHBOARD.product.assignvendor,
          // },
          // {
          //   title: "Map Short Code",
          //   path: PATH_DASHBOARD.product.mapshortcode,
          // },
        ],
      },
      {
        title: "Vendor Management",
        path: PATH_DASHBOARD.vendor.root,
        icon: ICONS.vendor,
        children: [
          // {
          //   title: "Add New Vendor",
          //   path: PATH_DASHBOARD.vendor.vendormanagement,
          // },
          {
            title: "Money Transfer Slots",
            path: PATH_DASHBOARD.vendor.moneytransferslots,
          },
          {
            title: "Credit Card Slots",
            path: PATH_DASHBOARD.vendor.creditcardslots,
          },
          {
            title: "Vendor Payment Slots",
            path: PATH_DASHBOARD.vendor.vendorpaymentslots,
          },
          {
            title: "DMT1 Slots",
            path: PATH_DASHBOARD.vendor.dmtslots,
          },
          {
            title: "DMT2 Slots",
            path: PATH_DASHBOARD.vendor.dmt2slots,
          },
          {
            title: "AEPS Slots",
            path: PATH_DASHBOARD.vendor.aepsslots,
          },
          {
            title: "BBPS Slots",
            path: PATH_DASHBOARD.vendor.bbpslots,
          },
          {
            title: "Transfer Slots",
            path: PATH_DASHBOARD.vendor.transferslots,
          },
          {
            title: "Plan Fetch Records",
            path: PATH_DASHBOARD.vendor.mplan,
          },
          // {
          //   title: "Transfer Slots",
          //   path: PATH_DASHBOARD.vendor.transferslots,
          // },
        ],
      },
      {
        title: "Fund Management",
        path: PATH_DASHBOARD.fundmanagement.root,
        icon: ICONS.fund,
        children: [
          {
            title: "Add New Bank",
            path: PATH_DASHBOARD.fundmanagement.addbank,
          },
          {
            title: "Admin Fund Flow",
            path: PATH_DASHBOARD.fundmanagement.adminfundflow,
          },
          {
            title: "AEPS Fund Flow",
            path: PATH_DASHBOARD.fundmanagement.aepsfundflow,
          },
          {
            title: "Fund Requests",
            path: PATH_DASHBOARD.fundmanagement.fundrequest,
          },
        ],
      },

      // {
      //   title: "BBPS Management",
      //   path: PATH_DASHBOARD.bbps.bbpsmanagement,
      //   icon: ICONS.dashboard,
      // },

      // {
      //   title: "BBPS Management",
      //   path: PATH_DASHBOARD.bbps.bbpsmamagements,
      //   icon: ICONS.dashboard,
      //   children: [
      //     {
      //       title: "BBPS",
      //       path: PATH_DASHBOARD.bbps.bbpsmamagements,
      //     },
      //     {
      //       title: "BBPS Products",
      //       path: PATH_DASHBOARD.bbps.bbpsproducts,
      //     },
      //   ],
      // },

      {
        title: "Auto Collect",
        path: PATH_DASHBOARD.autocollect.addautocollect,
        icon: ICONS.auto,
        children: [
          {
            title: "Add Auto Collect Services",
            path: PATH_DASHBOARD.autocollect.addautocollect,
          },
          {
            title: "Services",
            path: PATH_DASHBOARD.autocollect.services,
          },
          {
            title: "Auto Collect Table",
            path: PATH_DASHBOARD.autocollect.autocollecttable,
          },
        ],
      },

      // {
      //   title: "Roll Management",
      //   path: PATH_DASHBOARD.rollmanagement,
      //   icon: ICONS.dashboard,
      // },

      {
        title: "Content Management",
        path: PATH_DASHBOARD.contentmanagement.root,
        icon: ICONS.content,
        children: [
          {
            title: "FAQ Management",
            path: PATH_DASHBOARD.contentmanagement.faqmanagement,
          },
          {
            title: "Update Images",
            path: PATH_DASHBOARD.contentmanagement.updateimage,
          },
        ],
      },
      {
        title: "Tools",
        path: PATH_DASHBOARD.tools.root,
        icon: ICONS.tools,
        children: [
          {
            title: "SMS/Email Management ",
            path: PATH_DASHBOARD.tools.smsmanagement,
          },
          { title: "Approve User PAN", path: PATH_DASHBOARD.tools.panvarified },
          { title: "Update Neo E-Sign", path: PATH_DASHBOARD.tools.docusign },
          { title: "Vendor Switch ", path: PATH_DASHBOARD.tools.vendorswitch },
          { title: "Bank Master ", path: PATH_DASHBOARD.tools.bankmaster },
          {
            title: "Enable/Disable categories",
            path: PATH_DASHBOARD.tools.enablediscategories,
          },
          {
            title: "Wallet To Wallet Control",
            path: PATH_DASHBOARD.tools.walletToWallet,
          },
          { title: "AEPS", path: PATH_DASHBOARD.tools.aeps },
          {
            title: "Auxiliary Control",
            path: PATH_DASHBOARD.tools.auxiliarycontrol,
          },
          { title: "Other ", path: PATH_DASHBOARD.tools.Other },
        ],
      },
      {
        title: "reports",
        path: PATH_DASHBOARD.reports.root,
        icon: ICONS.reports,
        children: [
          {
            title: "All Transaction Records ",
            path: PATH_DASHBOARD.reports.alltransactionrecord,
          },
          {
            title: "Fund Flow Transactions",
            path: PATH_DASHBOARD.reports.fundflow,
          },

          {
            title: "Wallet Ladger ",
            path: PATH_DASHBOARD.reports.WalletLadger,
          },
          {
            title: "Hold duplicate Transaction Limit ",
            path: PATH_DASHBOARD.reports.WaitingAreaForDuplicateTxn,
          },
          {
            title: "Historical Data Exports ",
            path: PATH_DASHBOARD.reports.HistoricalDataExport,
          },
          {
            title: "UserWise Closing Balance",
            path: PATH_DASHBOARD.reports.UserWiseClosingBal,
          },
          {
            title: "TDS Ledger",
            path: PATH_DASHBOARD.reports.TDSLadger,
          },
          {
            title: "GST Ledger",
            path: PATH_DASHBOARD.reports.GSTLadger,
          },
        ],
      },
      // {
      //   title: "testreports",
      //   path: PATH_DASHBOARD.testreports.root,
      //   icon: ICONS.mail,
      //   children: [
      //     {
      //       title: "All Transaction Records",
      //       path: PATH_DASHBOARD.testreports.alltransactionrecordtest,
      //     },
      //     {
      //       title: "Fund Reports",
      //       path: PATH_DASHBOARD.testreports.fundreports,
      //     },
      //   ],
      // },
      {
        title: "Doc Api Reference",
        path: PATH_DASHBOARD.docapireference,
        icon: ICONS.documents,
      },

      {
        title: "AEPS 2 Data",
        path: PATH_DASHBOARD.aepstwo.root,
        icon: ICONS.finger,
        children: [
          {
            title: "AEPS 2 Agent Data ",
            path: PATH_DASHBOARD.aepstwo.AEPSTwoagentData,
          },
        ],
      },

      {
        title: "Admin Controls",
        path: PATH_DASHBOARD.admincontrol.root,
        icon: ICONS.content,
        children: [
          {
            title: "Maintenance Mode",
            path: PATH_DASHBOARD.admincontrol.AdminControlData,
          },
        ],
      },

      // {
      //   title: "Sales Management",
      //   path: PATH_DASHBOARD.salesmanagement,
      //   icon: ICONS.dashboard,
      // },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: "management",
  //   items: [
  //     {
  //       title: "user",
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: "Four", path: PATH_DASHBOARD.user.four },
  //         { title: "Five", path: PATH_DASHBOARD.user.five },
  //         { title: "Six", path: PATH_DASHBOARD.user.six },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
