import { useEffect, useState } from "react";
import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// auth
import { useAuthContext } from "../../auth/useAuthContext";
// components
import Iconify from "../../components/iconify";
import FormProvider, { RHFTextField } from "../../components/hook-form";

import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { PATH_AUTH } from "src/routes/paths";
import { requestPermission } from "./firebase";
import { fetchLocation } from "src/utils/fetchLocation";

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  afterSubmit?: string;
};

export default function AuthLoginForm() {
  const { Api } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useAuthContext();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    fetchLocation();
    requestPermission();
  }, []);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const body = {
        password: data.password,
        email: data.email,
        FCM_Token: sessionStorage.getItem("fcm"),
      };
      await Api(`admin/admin_login`, "POST", body, "").then((Response: any) => {
        if (Response?.status == 200) {
          if (Response.data.code == 200) {
            Api("admin/adminDetails", "GET", "", Response.data.data.token).then(
              (resp: any) => {
                if (resp.status == 200) {
                  if (resp.data.code == 200) {
                    login(Response.data.data.token, resp.data.data);
                  }
                }
              }
            );
            enqueueSnackbar(Response.data.message);
          } else {
            enqueueSnackbar(Response.data.message);
          }
        } else {
          enqueueSnackbar("Failed");
        }
      });
      // console.log('===========>',data.email)
    } catch (error) {
      if (error.code == 1) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
      reset();
      setError("afterSubmit", {
        ...error,
        message: error.message,
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {/* {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>} */}
        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link
          color="inherit"
          variant="subtitle2"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(PATH_AUTH.resetpassword)}
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{
          mt: 2,
          bgcolor: "text.primary",
          color: (theme) =>
            theme.palette.mode == "light" ? "common.white" : "grey.800",
          "&:hover": {
            bgcolor: "text.primary",
            color: (theme) =>
              theme.palette.mode == "light" ? "common.white" : "grey.800",
          },
        }}
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
